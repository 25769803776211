<template>
  <form @submit.prevent="search">
    <input type="text" placeholder="Search our inventory" v-model="searchData">
    <button @click="search">
      <img src="../../assets/search-icon.svg" alt="hourglass search icon">
    </button>
  </form>
</template>

<script>
export default {
  data() {
    return {
      searchData: ""
    };
  },
  methods: {
    search: function() {
      window.location.href = `/cart.php#!q=${this.searchData}`;
    }
  }
};
</script>

<style scoped>
form {
  display: flex;
}

input {
  border: 1px solid black;
  width: 250px;
  padding-left: 10px;
}

img {
  width: 18px;
  height: 18px;
  padding: 3px;
  background: grey;

  border: 1px solid black;
  border-left: 0;
}

button {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  height: 25px;
}
</style>
