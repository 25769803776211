<template>
  <ul class="drop-down-container">
    <a class="home" href="https://www.precisionreloading.com/index.php">Home</a>
    <nav-drop-down
      :items="[
        {name: 'Shotshell Reloading', url: '/cart.php#!c=1'},
        {name: 'Metallic Reloading', url: '/cart.php#!c=2'},
        {name: 'Bullet Casting', url: '/cart.php#!c=9'},
        {name: 'Powder and Primers', url: '/cart.php#!c=10'},
        {name: 'Shooting Gear', url: '/cart.php#!c=5'},
        {name: 'Gun Parts', url: '/cart.php#!c=397'},
        {name: 'Gun Cleaning & Care', url: '/cart.php#!c=6'},
        {name: 'Ammunition', url: '/cart.php#!c=8'},
        {name: 'Books DVD\'s & Software', url: '/cart.php#!c=7'},
        {name: 'Muzzleloading', url: '/cart.php#!c=3'},
        {name: 'PR Gear', url: '/cart.php#!c=365'},
        ]"
      href="https://www.precisionreloading.com/"
    >Categories</nav-drop-down>
  </ul>
</template>

<script>
import navDropDown from "../UI/Nav/navDropDown.vue";

export default {
  components: {
    navDropDown
  }
};
</script>

<style scoped>
.home {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
  margin-right: 10px;
}

.home:hover {
  border-bottom: 2px solid white;
}

.drop-down-container {
  padding: 0;
  margin: 0;
}

.drop-down-container > li {
  display: inline-block;
  margin-right: 40px;
}

.drop-down-container > li:last-child {
  margin-right: 0;
}

@media (max-width: 881px) {
  .drop-down-container {
    text-align: right;
  }

  .drop-down-container > li {
    display: block;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .drop-down-container > li:last-child {
    margin-right: 10px;
    margin-bottom: 0;
  }
}
</style>
