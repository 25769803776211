<template>
  <header>
    <!-- Mobile Header -->
    <div class="mobile-header">
      <div class="icons left">
        <text-icons type="shop"></text-icons>
        <text-icons type="cart"></text-icons>
      </div>

      <a href="/" class="logo">
        <img src="../../assets/precision_logo-white.png" alt="Precision Reloading" />
      </a>

      <div class="menu right-header">
        <mobile-menu></mobile-menu>
      </div>
    </div>

    <!-- Deskto Header -->
    <div class="full-header">
      <div class="left">
        <nav-drop-downs></nav-drop-downs>
        <search-box></search-box>
      </div>

      <div class="logo">
        <a href="/">
          <img src="../../assets/precision_logo-white.png" alt="Precision Reloading" />
          <p>Your Hunting & Shooting Outfitter</p>
        </a>
      </div>

      <div class="right-header">
        <div class="icons">
          <text-icons type="cart" bold></text-icons>
          <account-button></account-button>
        </div>

        <div class="customer-service-number">
          <p>1-800-223-0900</p>
          <p>Customer Service</p>
        </div>
      </div>
      <div class="notch">
        <div></div>
        <div></div>
      </div>
    </div>
  </header>
</template>

<script>
import mobileMenu from "./MobileMenu/MobileMenu.vue";
import textIcons from "./TextIcons.vue";
import navDropDowns from "./NavDropDowns.vue";
import searchBox from "./SearchBox.vue";
import accountButton from "./AccountButton.vue";

export default {
  components: {
    mobileMenu,
    textIcons,
    navDropDowns,
    searchBox,
    accountButton
  }
};
</script>

<style scoped>
header {
  background: url("../../assets/t13.png");
  width: 100%;
  height: 100px;
}

.mobile-header {
  position: relative;

  display: flex;
  justify-content: center;
  height: 90px;
}

.logo a {
  color: white;
  text-decoration: none;
}

.logo img {
  width: 175px;
  height: auto;
  margin-top: 12px;
}

.logo p {
  margin-top: 3px;
  font-size: 18px;
}

.menu {
  display: inline-block;
  position: absolute;

  height: 20px;
}

.right-header {
  position: absolute;
  right: 0;
}

.left {
  position: absolute;
  left: 0;
}

.icons {
  display: flex;
  bottom: 0;
  margin-bottom: 7px;
}

.full-header {
  /* Initally none, changes to flex in media query */
  display: none;
  justify-content: center;
}

.full-header .left,
.full-header .right-header {
  padding: 12px 5% 23px;
  height: 65px;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
}

.full-header .left {
  align-items: flex-start;
}

.full-header .right-header {
  align-items: flex-end;
}

.full-header .logo img {
  width: 200px;
  margin: 3px 0 0 0;
}

.full-header .logo p {
  font-size: 13px;

  position: relative;
  z-index: 2;
}

.notch {
  width: 100%;
  height: 29px;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 100px;
  z-index: 1;
  /* right:0 is to fix a flexbox issue in IE/Edge making the notch appear on the right side of the screen */
  right: 0;
}

.notch div {
  width: 180px;
}

.notch div:first-child {
  background: linear-gradient(45deg, white 20px, transparent 20px),
    url(../../assets/t13.png);
}

.notch div:last-child {
  background: linear-gradient(-45deg, white 20px, transparent 20px),
    url(../../assets/t13.png);
}

.customer-service-number {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  width: auto;
  display: inline-block;
}

.customer-service-number p:last-child {
  font-size: 12px;
  font-weight: normal;
}

@media (min-width: 882px) {
  .mobile-header {
    display: none;
  }

  .full-header {
    display: flex;
  }

  header {
    margin-bottom: 35px;
  }
}

@media (max-width: 445px) {
  .mobile-header .logo img {
    width: 120px;
    margin-top: 5px;
  }
}

@media (max-width: 1244px) {
  .full-header .left,
  .full-header .right-header {
    padding: 6px 10px 23px;
  }
}
</style>
