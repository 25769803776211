<template>
  <a v-if="href" class='container' :class="[{flipped: flipped}, size]" :href="href" :target="target">
    <div class='icon'>
      <slot name="icon"></slot>
    </div>
    <h3 :class="{bold: bold}"><slot></slot></h3>
  </a>

  <button v-else class='container' :class="[{flipped: flipped}, size]" @click="$emit('click')">
    <div class='icon'>
      <slot name="icon"></slot>
    </div>
    <h3 :class="{bold: bold}"><slot></slot></h3>
  </button>
</template>

<script>
export default {
  props: {
    href: String,
    target: String,
    flipped: Boolean,
    bold: Boolean,
    newTab: {
      type: Boolean,
      default: false
    },
    size: {
      // to be used with 'large' or 'extra-small' etc..
      validator: function(value) {
        return ['small', 'large'].includes(value);
      }
    }
  }
}
</script>

<style scoped>
h3 {
  font-weight: 400;
  font-size: 14px;
}

button.container {
  background: none;
  border: 0;
  font-size: 100%;
  padding: 0;
  font-family: inherit;
  width: auto;
  height: auto;
}

.container {
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: white;
  align-items: flex-end;
}

.container.flipped {
  flex-direction: row-reverse;
}

.container.flipped .icon {
  margin-right: 5px;
}

h3.bold {
  font-weight: bold;
  font-size: 18px;
}

.small .icon > img {
  width: 17px;
}

.icon > img {
  margin-left: 10px;
  width: 23px;
  height: auto;
}

@media (max-width: 881px) {
  .container {
    justify-content: flex-end;
  }
}
</style>
