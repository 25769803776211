<template>
  <li class="drop-down-item">
    <a :href="href">
      <h3>
        <slot></slot>
      </h3>
    </a>
    <div class="drop-down">
      <ul>
        <li class="items" v-for="item in items" :key="item.url">
          <a v-if="item.event" class="left" @click="$emit(item.event)">{{ item.name }}</a>
          <a v-else :href="item.url">{{ item.name }}</a>
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    items: {
      type: Array
    },
    href: {
      type: String
    }
  }
};
</script>

<style scoped>
.drop-down-item {
  margin: 0;
  padding: 0;
  list-style: none;
}

.drop-down-item:hover {
  text-decoration: underline;
}

.drop-down {
  display: none;
}

.drop-down-item:hover .drop-down {
  display: block;
}

.items {
  font-size: 12px;
}

.items:hover {
  border-bottom: 1px solid red;
  text-decoration: none;
  margin-bottom: -1px;
}

.drop-down-item h3 {
  cursor: pointer;
  padding: 0;
  margin: 0;

  /* Combats bad global style */
  font-size: 18px;
  font-weight: bold;
}

.drop-down-item a {
  text-decoration: none;
  color: white;
  cursor: pointer;
  text-align: left;
}

.drop-down {
  position: absolute;
  z-index: 300;
  padding: 5px;

  background: url(../../../assets/t13.png);
}

.drop-down ul {
  list-style: none;
  padding-left: 0;
}

.drop-down a {
  display: block;
  width: 150px;

  text-decoration: none;
  color: white;
  font-weight: bold;
  line-height: 23px;
}

@media (max-width: 881px) {
  .drop-down {
    display: none !important;
  }
}
</style>
