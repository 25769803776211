<template>
  <div>
    <nav-drop-down
      v-if="loggedInUser"
      :items="[{name: 'Order Status', url: '/ordStat.php'}, {name: 'Logout', url: 'url3', event: 'logout'}]"
      href="/account.php"
      @logout="logout"
    >
      <text-icon bold>
        <img src="../../assets/personIcon.svg" slot="icon" alt>
        {{loggedInUser}}
      </text-icon>
    </nav-drop-down>

    <text-icon v-else @click="login" bold>
      <h4>Login</h4>
      <img src="../../assets/personIcon.svg" slot="icon" alt>
    </text-icon>
  </div>
</template>

<script>
import textIcon from "../../components/UI/Nav/textIcon.vue";
import navDropDown from "../../components/UI/Nav/navDropDown.vue";

export default {
  components: {
    textIcon,
    navDropDown,
  },
  created() {
    // Selects a hidden DOM element made on the PHP side to check if the user is logged in
    const user = document.querySelector("#hidden-username");
    if (user) {
      this.loggedInUser = user.innerHTML;
    } else {
      this.loggedInUser = false;
    }
  },
  data() {
    return {
      loggedInUser: false
    };
  },
  methods: {
    async logout() {
      await fetch("/api/logout", { method: "POST" });
      window.location.replace("/logout.php");
    },
    login() {
      //  overlays.login() is a global function included in the PHP site
      window.overlays.login();
    }
  },
};
</script>

<style scoped>
</style>
