var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"drop-down-container"},[_c('a',{staticClass:"home",attrs:{"href":"https://www.precisionreloading.com/index.php"}},[_vm._v("Home")]),_c('nav-drop-down',{attrs:{"items":[
      {name: 'Shotshell Reloading', url: '/cart.php#!c=1'},
      {name: 'Metallic Reloading', url: '/cart.php#!c=2'},
      {name: 'Bullet Casting', url: '/cart.php#!c=9'},
      {name: 'Powder and Primers', url: '/cart.php#!c=10'},
      {name: 'Shooting Gear', url: '/cart.php#!c=5'},
      {name: 'Gun Parts', url: '/cart.php#!c=397'},
      {name: 'Gun Cleaning & Care', url: '/cart.php#!c=6'},
      {name: 'Ammunition', url: '/cart.php#!c=8'},
      {name: 'Books DVD\'s & Software', url: '/cart.php#!c=7'},
      {name: 'Muzzleloading', url: '/cart.php#!c=3'},
      {name: 'PR Gear', url: '/cart.php#!c=365'} ],"href":"https://www.precisionreloading.com/"}},[_vm._v("Categories")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }