<template>
  <text-icon v-if="type === 'cart'" href="shopping_cart.php" :flipped="flipped" :bold="bold">
    <img src="../../assets/cart.svg" slot="icon" alt />
    Cart
  </text-icon>

  <text-icon v-else-if="type === 'shop'" href="cart.php" :flipped="flipped" :bold="bold">
    <img src="../../assets/tag.png" slot="icon" alt />
    Shop
  </text-icon>

  <text-icon v-else-if="type === 'logout'" :flipped="flipped" :bold="bold" @click="logout">
    <img src="../../assets/personIcon.svg" slot="icon" alt />
    logout
  </text-icon>

  <text-icon v-else-if="type === 'login'" :flipped="flipped" :bold="bold" @click="login">
    <img src="../../assets/personIcon.svg" slot="icon" alt />
    login
  </text-icon>
</template>

<script>
import textIcon from "../UI/Nav/textIcon.vue";

export default {
  components: {
    textIcon
  },

  props: {
    type: String,
    flipped: Boolean,
    bold: Boolean
  },
  methods: {
    async logout() {
      await fetch("/api/logout", { method: "POST" });
      window.location.replace("/logout.php");
    },
    login() {
      //  overlays.login() is a global function included in the PHP site
      window.overlays.login();
    }
  }
};
</script>

<style scoped>
</style>