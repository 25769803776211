import { render, staticRenderFns } from "./TextIcons.vue?vue&type=template&id=79708709&scoped=true&"
import script from "./TextIcons.vue?vue&type=script&lang=js&"
export * from "./TextIcons.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79708709",
  null
  
)

export default component.exports