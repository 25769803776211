<template>
  <div class="mobile-menu" v-click-outside="clickoutsideHandler">
    <button class="hamburger-menu" @click="open = !open">
      <img class="hamburger-img" src="../../../assets/hamburger.svg" alt="precision reloading logo" />
    </button>
    <div v-if="open" class="mobile-menu-flyout" @click="open = !open">

      <text-icons v-if="loggedin" type="logout" flipped bold></text-icons>
      <text-icons v-else type="login" flipped bold></text-icons>

      <text-icons type="cart" flipped bold></text-icons>
      <a href="/wishlist.php"><h3>Wishlist</h3></a>
      <div class='line-break'></div>

      <a href="/"><h3>Home</h3></a>
      <a href="cart.php"><h3>Shop</h3></a>
      <a href="/view.php?pg=location"><h3>Contact Us</h3></a>
      <div class='line-break'></div>
    </div>
  </div>
</template>

<script>
import textIcons from "../TextIcons.vue";

export default {
  components: {
    textIcons
  },
  data() {
    return {
      open: false,
      loggedin: false
    }
  },
  methods: {
    clickoutsideHandler() {
      this.open = false;
    },
  },
  created() {
    // Selects a hidden DOM element made on the PHP side to check if the user is logged in
    const user = document.querySelector("#hidden-username");
    if (user) {
      this.loggedin = user.innerHTML;
    } else {
      this.loggedin = false;
    }
  },
}
</script>

<style scoped>
.mobile-menu {
  width: 100%;
  height: 100%;
}

.hamburger-menu {
  padding: 12px;
  margin: 0;
  height: 60px;
  width: 60px;

  background: none;
  border: none;
  box-shadow: none;
}

.hamburger-img {
  max-width: 100%;
  max-height: 100%;
}

.mobile-menu-flyout {
  background: url("../../../assets/t13.png");
  padding: 20px 30px;
  position: absolute;
  width: 102px;
  right: 0;
  text-align: right;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 100;
}


.mobile-menu-flyout a {
  color: white;
  text-decoration: none;
}

.mobile-menu-flyout a:hover,
.mobile-menu-flyout a:active {
  text-decoration: underline;
}

.mobile-menu-flyout > * {
  margin-top: 9px;
}

.line-break {
  width: 100%;
  border-bottom: 1px solid rgb(179, 35, 23);
}

</style>
