<template>
  <div id="app">
    <Header/>
  </div>
</template>

<script>
import Header from "./components/Header/Header.vue";

export default {
  name: "app",
  components: {
    Header
  }
};
</script>

<style>
html,
body {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  height: 100vh;
}

* {
  margin: 0;
}

input {
  border: none;
  border-bottom: 1px solid grey;
  outline: none;
}
</style>
